// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-auction-js": () => import("./../src/templates/auction.js" /* webpackChunkName: "component---src-templates-auction-js" */),
  "component---src-templates-lot-js": () => import("./../src/templates/lot.js" /* webpackChunkName: "component---src-templates-lot-js" */),
  "component---src-templates-region-js": () => import("./../src/templates/region.js" /* webpackChunkName: "component---src-templates-region-js" */)
}

